import { Injectable } from '@angular/core';
import {
  VmsStatus,
  LogbookCreate,
  UserBucket,
  ActivityLogPosts,
} from './model';
import {
  forkJoin,
  from,
  Observable,
  ObservableInput,
  throwError,
  timer,
} from 'rxjs';
import { catchError, map, retry, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { selectApiUrl } from '../settings/settings.selectors';
import { ISettingState } from '../settings/settings.reducer';
import { ActivityEndTripCreate } from './model/activityEndTripCreate';
import { AuthService } from '../auth/auth.service';
import { logout } from '../auth/auth.actions';
import { ToastService } from '@qld-recreational/toast';
import { IAuthState } from '../auth/auth.reducer';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import {
  ACCESS_TOKEN_KEY,
  AUTH_RESPONSE_KEY,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '../auth/auth';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';
import { IAppState, selectSessionId } from '../app-state/app-state.reducer';
import { isNil } from '@qld-recreational/ramda';
import { MESSAGES } from '../messages';
import { Device } from '@capacitor/device';
import { HttpResponse, CapacitorHttp } from '@capacitor/core';
import { PreferencesPayload } from '../preference/preference.service';
import { omit } from 'rambda';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private platform: Platform,
    private httpClient: HttpClient,
    private store: Store<ISettingState>,
    private authService: AuthService,
    private toastService: ToastService,
    private authStore: Store<IAuthState>,
    private appStateStore: Store<IAppState>
  ) {}

  private apiBaseUrl = this.store.select(selectApiUrl);

  /**
   * the api request wrapper to use angular for pwq requests or capacitor/http for native requests.
   * native requests will include jwt token in the header and handle token expire by checking response status
   * pwa requests jwt token and token expiry will be handled by interceptors
   * @param nativeRequest: The native request handler
   * @param webRequest: The pwa request handler
   */
  public apiWrapper(
    nativeRequest: (
      apiBaseUrl: string,
      headers: { Authorization: string; 'Content-Type': string },
      responseHandler: (response: HttpResponse) => Promise<any>,
      errorHandler: (
        err: HttpResponse,
        caught: Observable<any>
      ) => ObservableInput<any>
    ) => Observable<any>,
    webRequest: (apiBaseUrl: string) => Observable<any>
  ) {
    return forkJoin({
      token: this.authService.getAccessToken().pipe(take(1)),
      apiBaseUrl: this.apiBaseUrl.pipe(take(1)),
    }).pipe(
      switchMap(({ token, apiBaseUrl }) => {
        if (isNil(token)) {
          return throwError(MESSAGES.invalidAccessTokenError);
        }
        return Capacitor.isNativePlatform()
          ? nativeRequest(
              apiBaseUrl,
              {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              async (response) => {
                if (response.status === 401) {
                  await this.sentryLog(response);
                  this.toastService.presentWarningToast(
                    'token expired, please login again'
                  );
                  this.authStore.dispatch(logout());
                }
                if (response.status >= 300) {
                  throw response;
                }
                return response.data;
              },
              (error) => {
                throw error;
              }
            )
          : webRequest(apiBaseUrl);
      })
    );
  }

  private async sentryLog(response) {
    const accessToken = (await Preferences.get({ key: ACCESS_TOKEN_KEY }))
      .value;
    const authResponse = JSON.parse(
      (await Preferences.get({ key: AUTH_RESPONSE_KEY })).value
    );
    const idToken = (await Preferences.get({ key: ID_TOKEN_KEY })).value;
    const refreshToken = (await Preferences.get({ key: REFRESH_TOKEN_KEY }))
      .value;
    Sentry.withScope((scope) => {
      scope.setExtras({
        response,
        accessToken,
        authResponse,
        idToken,
        refreshToken,
      });
      Sentry.captureMessage('Interceptor captured error');
    });
  }

  /**
   * The post request wrapper to call apiWrapper with pwa and native request handler
   * requestId, sessionID, clientVersion, deviceInfo will be added to the body
   * @param endpoint: The endpoint of the post request
   * @param body: The body of the post request
   */
  public postWithBody(endpoint: string, body: any) {
    return forkJoin({
      sessionID: this.appStateStore.select(selectSessionId).pipe(take(1)),
      deviceID: from(Device.getId()).pipe(
        map((id) => ({
          uuid: id.identifier,
        }))
      ),
      deviceInfo: from(Device.getInfo()),
    }).pipe(
      switchMap(({ sessionID, deviceInfo, deviceID }) => {
        const data = {
          ...body,
          requestId: body.requestId || uuidv4(),
          clientVersion: body.clientVersion || environment.buildNumber,
          sessionID,
          deviceInfo,
          deviceID,
        };
        return this.apiWrapper(
          (apiBaseUrl, headers, responseHandler, errorHandler) =>
            from(
              CapacitorHttp.post({
                url: `${apiBaseUrl}/${endpoint}`,
                // https://github.com/apple/swift-corelibs-foundation/issues/4255
                data: JSON.stringify(data),
                headers,
              })
            ).pipe(switchMap(responseHandler), catchError(errorHandler)),
          (apiBaseUrl) =>
            this.httpClient.post(`${apiBaseUrl}/${endpoint}`, data)
        );
      })
    );
  }

  public getWithParams(endpoint: string, params: any) {
    return this.apiWrapper(
      (apiBaseUrl, headers, responseHandler, errorHandler) =>
        from(
          CapacitorHttp.get({
            url: `${apiBaseUrl}/${endpoint}`,
            params,
            headers,
          })
        ).pipe(switchMap(responseHandler), catchError(errorHandler)),
      (apiBaseUrl) =>
        this.httpClient.get(`${apiBaseUrl}/${endpoint}`, { params })
    );
  }

  public postActivityLogs(activityLog: ActivityLogPosts) {
    return this.postWithBody('activity-logs', activityLog);
  }

  public postLogbook(logbookCreate: LogbookCreate) {
    return this.postWithBody('logbook', logbookCreate);
  }

  public getUserBucket(): Observable<UserBucket> {
    return this.postWithBody('user-buckets', {});
  }

  public getVMS(): Observable<VmsStatus> {
    return this.postWithBody('vms-status', {});
  }

  /**
   * TODO: Mock save for now. Replace with actual implementation when ready
   * @returns
   */
  public saveUserPreference(payload: PreferencesPayload): Observable<unknown> {
    return this.postWithBody('commit-preferences', payload).pipe(retry(2));
  }

  public getUserPreferences(): Observable<PreferencesPayload> {
    return this.postWithBody('preferences', {}).pipe(
      map((payload: PreferencesPayload) =>
        omit(
          ['deviceID', 'clientVersion', 'deviceInfo', 'requestId', 'sessionID'],
          payload
        )
      ),
      retry({
        resetOnSuccess: true,
        delay: (_, count) => timer(Math.min(count * 1_000, 5_000)),
      })
    );
  }

  public endTrip(
    activityEndTripCreate: Omit<ActivityEndTripCreate, 'clientVersion'>
  ) {
    Sentry.captureMessage(
      `${activityEndTripCreate.tripCorrelationID}::End trip request push`
    );
    return this.postWithBody('activity-trip-end', activityEndTripCreate);
  }

  public getAWSWithParams(endpoint: string, params: any) {
    return this.apiWrapper(
      (apiBaseUrl, headers, responseHandler, errorHandler) =>
        from(
          CapacitorHttp.request({
            url: `${endpoint}`,
            method: 'get',
            params,
            headers,
          })
        ).pipe(switchMap(responseHandler), catchError(errorHandler)),
      (apiBaseUrl) => this.httpClient.get(`${endpoint}`, { params })
    );
  }
}

import { Injectable } from '@angular/core';
import { from, Observable, of, throwError, TimeoutError, timer } from 'rxjs';
import {
  catchError,
  map,
  retry,
  switchMap,
  take,
  timeout,
} from 'rxjs/operators';
import { BackgroundRequest } from './background-request.model';
import { ApiService } from '../api/api.service';
import { networkConnected } from '@qld-recreational/network';
import { HttpErrorResponse } from '@angular/common/http';
import { equals } from 'rambda';

export enum BackgroundRequestPath {
  PreTrip = 'activity-pretrip',
  AmendNotice = 'activity-pretrip-amend',
  NilFishing = 'nil-fishing',
  Logbook = 'logbook',
  PriorNotice = 'activity-prior',
  EmergencyNotice = 'activity-emergency',
  RetainNotice = 'activity-retained',
  WeightNotice = 'activity-weight',
  ManualPositions = 'manual-positions',
}

@Injectable({
  providedIn: 'root',
})
export class BackgroundRequestService {
  private readonly PING_TIMEOUT = 1_000 * 2;
  private readonly PING_RETRY_COUNT = 3;
  private readonly NO_DNS_ERROR =
    'A server with the specified hostname could not be found.';

  constructor(private apiService: ApiService) {}

  public checkConnectivity() {
    return from(networkConnected()).pipe(
      switchMap((connected) => {
        if (!connected) {
          return of(connected);
        }

        return this.apiService.getWithParams('ping', {}).pipe(
          take(1),
          timeout(this.PING_TIMEOUT),
          retry({
            count: this.PING_RETRY_COUNT,
            delay: (error) => {
              // retry if the error is a timeout because it sometimes randomly happens after login, so we want to double check
              if (
                error instanceof TimeoutError ||
                equals(error.message, this.NO_DNS_ERROR)
              ) {
                return timer(this.PING_TIMEOUT / 2);
              }

              // if it's another type of error, just continue
              return throwError(() => error);
            },
          }),
          map(() => true),
          catchError((error: HttpErrorResponse | TimeoutError) => {
            if (
              error instanceof TimeoutError ||
              equals(error.message, this.NO_DNS_ERROR)
            ) {
              return of(false);
            } else {
              return of(!equals(error.status, 0));
            }
          })
        );
      })
    );
  }

  public submit(backgroundRequests: BackgroundRequest): Observable<{
    backgroundRequest: BackgroundRequest;
    transactionNumber: string;
  }> {
    return this.apiService
      .postWithBody(backgroundRequests.path, {
        ...backgroundRequests.payload,
        requestId: backgroundRequests.requestId,
      })
      .pipe(
        map((response) => ({
          backgroundRequest: backgroundRequests,
          transactionNumber: response?.receipt,
        }))
      );
  }
}

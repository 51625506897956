import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { selectApiUrl, selectApiUrlSV } from '../settings/settings.selectors';
import { Store } from '@ngrx/store';

export const TokenInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  const settingStore = inject(Store);
  return settingStore.select(selectApiUrl).pipe(
    withLatestFrom(settingStore.select(selectApiUrlSV)),
    map(
      ([apiUrl, apiUrlSV]) =>
        request.url.includes(apiUrl) || request.url.includes(apiUrlSV)
    ),
    switchMap((isApiUrl) => {
      if (!isApiUrl) {
        return next(request);
      }
      return authService.getAccessToken().pipe(
        take(1),
        switchMap((token) => {
          const transformedRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next(transformedRequest);
        })
      );
    })
  );
};

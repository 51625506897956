import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  checkUpdateFailure,
  init,
  retry,
  syncAssets,
  syncAssetsFailure,
  syncAssetsSuccess,
  syncEntries,
  syncEntriesFailure,
  syncEntriesSuccess,
} from './contentful.actions';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IContentfulState } from './contentful.reducer';
import { ContentfulService } from './contentful.service';
import { of } from 'rxjs';
import { equals } from '@qld-recreational/ramda';
import { ISettingState } from '../settings/settings.reducer';
import { selectTargetEnv } from '../settings/settings.selectors';
import { switchContentfulContext } from '../settings/settings.actions';

@Injectable()
export class ContentfulEffects {
  private readonly MOCK = 'mock';

  public checkUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retry, init),
      withLatestFrom(this.settingStore.select(selectTargetEnv)),
      filter(([_, targetEnv]) => !equals(targetEnv, this.MOCK)),
      tap(() => this.contentfulService.preventScreenSleep()),
      switchMap(() =>
        this.contentfulService.checkRemoteUpdate().pipe(
          map((syncCollection) => syncEntries({ syncCollection })),
          catchError((err) =>
            of(
              checkUpdateFailure({
                error: err?.message || 'Sync Entries Error',
              })
            )
          )
        )
      )
    )
  );

  public changeStage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(switchContentfulContext),
        switchMap(() => this.contentfulService.clearCache())
      ),
    { dispatch: false }
  );

  public syncEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncEntries),
      switchMap(({ syncCollection }) =>
        this.contentfulService.syncEntries(syncCollection).pipe(
          map((collection) =>
            syncEntriesSuccess({ syncCollection: collection })
          ),
          catchError((err) =>
            of(
              syncEntriesFailure({
                error: err?.message || 'Sync Entries Error',
              })
            )
          )
        )
      )
    )
  );

  public syncEntriesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncEntriesSuccess),
      map((syncCollection) => syncAssets(syncCollection))
    )
  );

  public syncAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncAssets),
      switchMap(({ syncCollection }) =>
        this.contentfulService.syncAssets(syncCollection).pipe(
          map((nextSyncToken) => syncAssetsSuccess({ nextSyncToken })),
          catchError((err) =>
            of(
              syncAssetsFailure({ error: err?.message || 'Sync Assets Error' })
            )
          )
        )
      )
    )
  );

  public syncAssetsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(syncAssetsSuccess),
        tap(() => this.contentfulService.allowScreenSleep()),
        map(({ nextSyncToken }) =>
          this.contentfulService.updateNextToken(nextSyncToken)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private contentfulStore: Store<IContentfulState>,
    private settingStore: Store<ISettingState>,
    private contentfulService: ContentfulService
  ) {}
}

import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectApiUrl, selectApiUrlSV } from '../settings/settings.selectors';
import { logout } from './auth.actions';
import { IAuthState } from './auth.reducer';
import * as Sentry from '@sentry/browser';
import { Preferences } from '@capacitor/preferences';
import { ToastService } from '@qld-recreational/toast';
import {
  ACCESS_TOKEN_KEY,
  AUTH_RESPONSE_KEY,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from './auth';

const sendTokenExpireSentryError = async (
  request: HttpRequest<any>,
  error: HttpErrorResponse
) => {
  const accessToken = (await Preferences.get({ key: ACCESS_TOKEN_KEY })).value;
  const authResponse = JSON.parse(
    (await Preferences.get({ key: AUTH_RESPONSE_KEY })).value
  );
  const idToken = (await Preferences.get({ key: ID_TOKEN_KEY })).value;
  const refreshToken = (await Preferences.get({ key: REFRESH_TOKEN_KEY }))
    .value;
  Sentry.withScope((scope) => {
    scope.setExtras({
      url: request.url,
      error,
      accessToken,
      authResponse,
      idToken,
      refreshToken,
    });
    Sentry.captureMessage('Interceptor captured error');
  });
};

const checkForExpiredToken = async (
  request: HttpRequest<any>,
  error: HttpErrorResponse,
  toastService: ToastService,
  authStore: Store<IAuthState>
) => {
  if (error instanceof HttpErrorResponse) {
    if (error.status === 401) {
      await sendTokenExpireSentryError(request, error);
      await toastService.presentWarningToast(
        'token expired, please login again'
      );
      authStore.dispatch(logout());
    }
  }
};

export const ExpiredTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const authStore = inject(Store);
  const settingStore = inject(Store);
  const toastService = inject(ToastService);
  return settingStore.select(selectApiUrl).pipe(
    withLatestFrom(settingStore.select(selectApiUrlSV)),
    map(
      ([apiUrl, apiUrlSV]) =>
        request.url.includes(apiUrl) || request.url.includes(apiUrlSV)
    ),
    switchMap((isApiUrl) =>
      next(request).pipe(
        tap({
          next: undefined,
          error: async (error: any) => {
            if (isApiUrl && !request.url.includes('login')) {
              await checkForExpiredToken(
                request,
                error,
                toastService,
                authStore
              );
            }
          },
        })
      )
    )
  );
};

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ISettingState } from '../settings/settings.reducer';
import OneSignal from 'onesignal-cordova-plugin';
import {
  filter,
  map,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { INotificationState } from './notification.reducer';
import { selectNotificationsLastLoadedDateTime } from './notification.selectors';
import {
  loadNewNotifications,
  loadNewNotificationsSuccess,
} from './notification.actions';
import { Platform } from '@ionic/angular';
import {
  IAuthState,
  selectEmail,
  selectViewStatus,
} from '../auth/auth.reducer';
import { equals, isNil } from '@qld-recreational/ramda';
import { ViewStatus } from '../shared/ViewStatus';
import { ApiService } from '../api/api.service';
import { utcNow } from '@qld-recreational/moment';
import { selectApiUrlSV } from '../settings/settings.selectors';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private settingsStore: Store<ISettingState>,
    private authStore: Store<IAuthState>,
    private platform: Platform,
    private notificationStore: Store<INotificationState>,
    private apiService: ApiService
  ) {}

  public async initNotification() {
    // Enable to debug issues:
    // OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });
    if (!this.platform.is('hybrid')) {
      return;
    }
    await this.platform.ready();

    OneSignal.setAppId(environment.oneSignalAppId);

    OneSignal.setNotificationWillShowInForegroundHandler(
      (notificationEvent) => {
        notificationEvent.complete(notificationEvent.getNotification());
      }
    );

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using
    //    an In-App Message to prompt for notification permission (See step 7) to better communicate
    //    to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log('User accepted notifications: ' + accepted);
    });

    this.syncNotifications();
  }

  public subscriptionNotifications(email: string) {
    OneSignal.sendTag('email', email);
    OneSignal.disablePush(false);
  }

  private syncNotifications() {
    OneSignal.setNotificationWillShowInForegroundHandler(
      (notificationEvent) => {
        const notification = notificationEvent.getNotification();
        this.notificationStore.dispatch(
          loadNewNotificationsSuccess({
            notifications: [
              {
                id: notification.notificationId,
                heading: notification.title,
                content: notification.body,
                url: undefined,
                dateTime: utcNow(),
              },
            ],
          })
        );
      }
    );
    this.platform.resume
      .pipe(
        startWith('Platform is ready'),
        switchMap(() => this.authStore.select(selectViewStatus).pipe(take(1))),
        filter((viewStatus) => !equals(viewStatus, ViewStatus.Loading)),
        switchMap(() => this.authStore.select(selectEmail).pipe(take(1))),
        filter((email) => !isNil(email))
      )
      .subscribe(() => {
        this.notificationStore.dispatch(loadNewNotifications());
      });
  }

  public loadNewNotifications() {
    return from(
      new Promise((resolve, reject) => {
        OneSignal.getDeviceState((event) => {
          resolve(event);
        });
      })
    ).pipe(
      map(({ userId }) => userId),
      withLatestFrom(
        this.notificationStore.select(selectNotificationsLastLoadedDateTime),
        this.settingsStore.select(selectApiUrlSV)
      ),
      switchMap(([deviceId, lastLoadedDateTime, api]) =>
        this.apiService.getAWSWithParams(`${api}/get-notification`, {
          deviceId,
          lastLoadedDateTime,
        })
      )
    );
  }
}
